import { GetWeavePopNotificationByType } from '@frontend/types';
import { useCommonActionSubscriptions } from './actions/use-common-actions-subscriptions';
import { useNotificationActionSubscription } from './actions/use-notification-action-subscription';
import { useNotification } from './use-notification';

type Props = {
  onView?: (notification: GetWeavePopNotificationByType<'call-record-tag'>) => void;
};

export const useCallRecordTagNotification = ({ onView }: Props = {}) => {
  const { create, remove } = useNotification<'call-record-tag'>();
  useCommonActionSubscriptions('call-record-tag');

  useNotificationActionSubscription('call-record-tag', 'view', (e) => {
    onView?.(e.notification);
    remove(e.notification.id);
  });

  return {
    create,
    remove,
  };
};
