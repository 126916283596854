import { TagsV2 } from '@frontend/api-tag';
import {
  useWebsocketCallQueueEventSubscription,
  useWebsocketDeskPhoneEventSubscription,
  useWebsocketVoicemailEventSubscription,
  useWebsocketCallRecordEventSubscription,
} from '@frontend/calls-v2';
import { useSoftphoneInnerManager } from '@frontend/weave-softphone-manager';
import {
  useWebsocketFaxCountEventSubscription,
  useWebsocketFaxEventSubscription,
  useWebsocketFaxReadEventSubscription,
} from './fax';
import {
  useSMSManualScheduledWSSubscription,
  useSMSDataV3WSSubscription,
  useSMSPreferenceEventSubscription,
  useSMSTagNotificationSubscription,
} from './messaging';
import { useWebsocketPhonesTagEventSubscription } from './phones/use-phones-tag-notification-event-subscription';
import { useWebsocketFollowupEventSubscription } from './use-followup-event-subscription';
import { useWebsocketFormEventSubscription } from './use-form-event-subscription';
import { useWebsocketPaymentsEventSubscription } from './use-payments-event-subscription';
import { useWebsocketReviewEventSubscription } from './use-review-event-subscription';
import { useWebsocketScheduleRequestEventSubscription } from './use-schedule-request-event-subscription';

export const useAllWebsocketEventSubscriptions = () => {
  const hasSoftphone = useSoftphoneInnerManager((ctx) => ctx?.isConnected);
  /**
   * Note: not all websocket event subscriptions need to be in here, but this is a convenient place to put them if they're top level
   * For example, some events need specific context to be available before they can be subscribed to properly,
   * such as softphones and team chat events
   **/
  useWebsocketFaxCountEventSubscription();
  useWebsocketFaxEventSubscription();
  useWebsocketFaxReadEventSubscription();
  useWebsocketFollowupEventSubscription();
  useWebsocketFormEventSubscription();
  useWebsocketDeskPhoneEventSubscription({ enabled: !hasSoftphone });
  useWebsocketCallQueueEventSubscription();
  useWebsocketVoicemailEventSubscription();
  useWebsocketCallRecordEventSubscription();
  useWebsocketPhonesTagEventSubscription();
  useWebsocketReviewEventSubscription();
  useSMSPreferenceEventSubscription();
  useWebsocketScheduleRequestEventSubscription();
  useWebsocketPaymentsEventSubscription();
  TagsV2.useTagsWSSubscriptions();
  useSMSTagNotificationSubscription();
  useSMSDataV3WSSubscription();
  useSMSManualScheduledWSSubscription();
};
