import { useLayoutEffect, useState } from 'react';
import { css } from '@emotion/react';
import { TagsV2 } from '@frontend/api-tag';
import { getWeaveToken } from '@frontend/auth-helpers';
import appConfig from '@frontend/env';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useAppScopeStore } from '@frontend/scope';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import { Accordion, Button, Heading, Text, useAlert } from '@frontend/design-system';
import { useRecentCallsTagMutation } from '../../../hooks/use-tags';
import { recentCallsTrackingId } from '../../../pendo-tracking';
import { getFullName } from '../../../utils';
import { ContactSection } from '../../transcription/transcription-panel-sections';
import { AddTag } from '../recent-calls-tag-selector';
import { getRecentCallsUniqueKey } from '../utils';
import { CallDetailsSection, CallRecordingSection, ContactActions, TagsSection } from './call-details-panel-sections';
import { CallDetailsContext, CallDetailsPanelAccordionType } from './types';

export const CallDetailsPanel = () => {
  const { t } = useTranslation('calls');
  const { selectedOrgId, selectedLocationIds } = useAppScopeStore();
  const { show, setShow, context } = useSlidePanelShallowStore<CallDetailsContext>('setShow', 'context', 'show');
  const alerts = useAlert();
  const weaveToken = getWeaveToken();
  const { getLocationName } = useAppScopeStore();
  const hasAccessToRecordings = !!context?.hasAccessToRecordings;
  const row = context?.rows.find((row) => row.id === context.currentRowId);
  const personId = row?.person?.personId || '';
  const firstName = row?.person?.firstName || '';
  const lastName = row?.person?.lastName || '';
  const personName = getFullName(row?.person?.firstName, row?.person?.lastName);
  const contactNumber = formatPhoneNumber(row?.direction === 'inbound' ? row?.callerNumber : row?.dialedNumber);
  const locationId = row?.locationId;
  const callDetailsUniqueKey = !!row ? getRecentCallsUniqueKey(row) : '';
  const currentRowIndex = context?.rows.findIndex((row) => getRecentCallsUniqueKey(row) === context.currentRowId) ?? 0;
  const hasCallRecording = !!row?.recordingPath && !!row?.recordingFilename;
  const callRecordingPath = !!row
    ? `${appConfig.BACKEND_API}/portal/v1/phone/callRecordings/${row.channelId}?location_id=${locationId}&token=${weaveToken}`
    : '';

  const [openedAccordionId, setOpenedAccordionId] = useState<CallDetailsPanelAccordionType>(
    context?.sectionFocus ?? 'details'
  );

  const { data: tagData } = TagsV2.Queries.useListTagsQuery({
    request: { orgId: selectedOrgId, groupIds: selectedLocationIds },
    options: {
      enabled: row?.tags?.length === 0,
    },
  });

  const mappedTags =
    tagData?.tags.reduce((acc, tag) => {
      acc[tag.id] = tag;
      return acc;
    }, {} as Record<string, (typeof tagData)['tags'][number]>) ?? {};

  const assignedTags = !!row && row.tags?.length > 0 ? row.tags : row?.tagIds?.map((tagId) => mappedTags[tagId]) || [];

  useLayoutEffect(() => {
    if (row) {
      context?.toggleRowSelected?.(callDetailsUniqueKey, true);
      const rowElement = document.querySelectorAll(`#${context?.instanceId} [role=row]`)[currentRowIndex];
      rowElement?.scrollIntoView({ behavior: 'smooth' });
    }
    return () => {
      if (context && row) {
        context.toggleRowSelected?.(callDetailsUniqueKey, false);
      }
    };
  }, [row]);

  useLayoutEffect(() => {
    if (show && !!context?.sectionFocus && openedAccordionId !== context.sectionFocus) {
      setOpenedAccordionId(context.sectionFocus);
    }
  }, [openedAccordionId, context?.sectionFocus, show]);

  const { removeTag } = useRecentCallsTagMutation({
    queryKey: context?.callDetailsQueryKey ?? [],
    channelId: row?.channelId ?? '',
    callStartedAt: row?.startedAt ?? '',
    callRecordLocationId: row?.locationId ?? '',
    onSuccess: ({ op }) => {
      if (op === 'remove') alerts.success(t('Successfully removed tag.'));
    },
    onFailure: ({ op }) => {
      if (op === 'remove') alerts.error(t('Failed to remove tag.'));
    },
  });

  const { applyTag } = useRecentCallsTagMutation({
    queryKey: context?.callDetailsQueryKey ?? [],
    channelId: row?.channelId ?? '',
    callStartedAt: row?.startedAt ?? '',
    callRecordLocationId: row?.locationId ?? '',
    onSuccess: ({ op }) => {
      if (op === 'add') alerts.success(t('Tag added successfully.'));
    },
    onFailure: ({ op }) => {
      if (op === 'remove') alerts.error(t('Tag removed successfully.'));
    },
  });

  if (!context || !row) {
    return null;
  }

  return (
    <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr auto', height: '100%', padding: theme.spacing(3) }}>
      <header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: theme.spacing(1),
        }}
      >
        <Heading level={2} css={{ margin: 0 }}>
          {t('Call Details')}
        </Heading>
        <Button
          variant='secondary'
          label={t('Close panel')}
          iconName='x'
          size='large'
          onClick={() => {
            setShow(false);
          }}
        />
      </header>
      {row && (
        <div style={{ overflow: 'auto' }}>
          <ContactSection
            contact={{
              id: personId,
              firstName,
              lastName,
              profileName: personName,
              defaultName: t('Unknown'),
              number: contactNumber,
            }}
            locationId={row.locationId ?? ''}
          >
            <ContactActions
              contact={{ name: personName, id: personId, number: contactNumber }}
              locationId={row.locationId ?? ''}
            />
          </ContactSection>
          {hasCallRecording && hasAccessToRecordings && (
            <CallRecordingSection row={row} callRecordingPath={callRecordingPath} />
          )}

          <Accordion
            variant='blank'
            padding={0}
            controlledValue={openedAccordionId}
            onChange={(value) => {
              setOpenedAccordionId(value as CallDetailsPanelAccordionType);
              setShow(true, 'callDetails', {
                ...context,
                sectionFocus: value as CallDetailsPanelAccordionType,
              });
            }}
            css={css`
              > div:not(:last-child) {
                margin-bottom: ${theme.spacing(2)};
              }
            `}
          >
            <Accordion.Item title={t('About this Call')} value='details' css={styles.accordionItemStyle}>
              <Accordion.Header
                title={t('About this Call')}
                css={styles.accordionHeaderStyle(openedAccordionId, 'details')}
              />
              <Accordion.Body css={{ padding: 0 }}>
                <CallDetailsSection row={row} locationName={getLocationName(row.locationId ?? '')} />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item title={t('Tags')} value='tags' css={styles.accordionItemStyle}>
              <Accordion.Header css={styles.accordionHeaderStyle(openedAccordionId, 'tags')}>
                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      gap: ${theme.spacing(0.5)};
                    `}
                  >
                    <Text
                      css={css`
                        font-size: ${theme.fontSize(18)};
                      `}
                      weight='bold'
                    >
                      {t('Tags')}
                    </Text>
                    <Text size='small' color='subdued'>
                      {assignedTags?.length > 0 ? assignedTags?.length : ''}
                    </Text>
                  </div>
                  <AddTag existingTagIds={assignedTags?.map((tag) => tag.id)} onApplyTag={applyTag} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <TagsSection tags={assignedTags} onApplyTag={applyTag} onRemoveTag={removeTag} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
      <div
        css={{
          display: 'flex',
          justifyContent: 'end',
          padding: theme.spacing(2, 1, 1),
          gap: theme.spacing(1),
        }}
      >
        <Button
          variant='secondary'
          size='large'
          trackingId={recentCallsTrackingId({ context: `panel::call-details::previous` })}
          disabled={currentRowIndex - 1 < 0}
          onClick={() => {
            if (!!context) {
              setShow(true, 'callDetails', {
                currentRowId: !!context?.rows ? getRecentCallsUniqueKey(context?.rows?.[currentRowIndex - 1]) : '',
                rows: context.rows,
                hasAccessToRecordings,
                instanceId: context.instanceId,
                toggleRowSelected: context.toggleRowSelected,
                callDetailsQueryKey: context.callDetailsQueryKey,
                sectionFocus: context.sectionFocus,
              });
            }
          }}
        >
          {t('Previous')}
        </Button>
        <Button
          variant='secondary'
          size='large'
          trackingId={recentCallsTrackingId({ context: `panel::call-details::next` })}
          disabled={currentRowIndex === (context?.rows?.length || 0) - 1}
          onClick={() => {
            if (!!context) {
              setShow(true, 'callDetails', {
                currentRowId: !!context?.rows ? getRecentCallsUniqueKey(context?.rows?.[currentRowIndex + 1]) : '',
                rows: context.rows,
                hasAccessToRecordings,
                instanceId: context.instanceId,
                toggleRowSelected: context.toggleRowSelected,
                callDetailsQueryKey: context.callDetailsQueryKey,
                sectionFocus: context.sectionFocus,
              });
            }
          }}
        >
          {t('Next')}
        </Button>
      </div>
    </div>
  );
};

const styles = {
  accordionHeaderStyle: (
    openedAccordionId: CallDetailsPanelAccordionType,
    currentAccordionType: CallDetailsPanelAccordionType
  ) => css`
    background-color: ${openedAccordionId === currentAccordionType ? theme.colors.neutral5 : 'transparent'};
    border-bottom: none;
    padding: ${theme.spacing(2, 0, 2, 2)};
  `,

  accordionItemStyle: css`
    border-radius: ${theme.spacing(1)};
    border: 1px solid ${theme.colors.neutral20};
    > div {
      display: block !important;
    }
    :last-child {
      border-bottom: 1px solid ${theme.colors.neutral20};
    }
  `,
};
