import { useEffect, useState } from 'react';
import { useMatch } from '@tanstack/react-location';
import { EmailTemplate } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/email-template/v1/emailtemplate.pb';
import { CampaignType_Enum as CampaignType } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import dayjs from 'dayjs';
import { EmailTemplateQueries } from '@frontend/api-email-template';
import { DeviceSize } from '@frontend/device-size-field';
import { EmailPreviewModal } from '@frontend/email-preview-modal';
import { EmailThumbnail } from '@frontend/email-thumbnail';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { ListRow, NakedButton, PopoverMenu, Text, useAlert, usePopoverMenu } from '@frontend/design-system';
import { useBulkEmailEditorShallowStore, useBulkMessagingNavigator, usePreviewModal } from '../../hooks';
import { TemplateActionsMenuOptions } from './types';

const DATE_FORMAT = 'MMMM D, YYYY';
const DATE_FORMAT_WITHOUT_YEAR = 'MMMM D';
const CUSTOM_BORDER_RADIUS = theme.spacing(0.75); // There's a weird gap when the border radius matches the container's border radius

export const DefaultTemplateCard = ({
  id: templateId,
  createdAt,
  subject,
  thumbnailUrl,
  title,
  updatedAt,
}: EmailTemplate) => {
  const { t } = useTranslation('bulk-messaging');
  const navigateTo = useBulkMessagingNavigator(CampaignType.EMAIL, true);
  const alert = useAlert();
  const { params } = useMatch();
  const campaignId = params.id;

  const currentYear = dayjs().year();
  const createdAtYear = dayjs(createdAt).year();
  const updatedAtYear = dayjs(updatedAt).year();

  const dateText =
    updatedAt === createdAt
      ? t('Created {{date}}', {
          date: dayjs(createdAt).format(currentYear === createdAtYear ? DATE_FORMAT_WITHOUT_YEAR : DATE_FORMAT),
        })
      : t('Updated {{date}}', {
          date: dayjs(updatedAt).format(currentYear === updatedAtYear ? DATE_FORMAT_WITHOUT_YEAR : DATE_FORMAT),
        });
  const {
    close: closePopoverMenu,
    getMenuProps,
    getTriggerProps,
  } = usePopoverMenu({
    placement: 'bottom-end',
    middlewareOptions: { offset: 0 },
  });
  const store = useBulkEmailEditorShallowStore('setSubject', 'setTemplateHtml', 'setTemplateJson');

  const [previewHtml, setPreviewHtml] = useState<string>();
  const { deviceSizeProps, generatePreview, previewModalProps, transformedText } = usePreviewModal(previewHtml);

  useEffect(() => {
    if (transformedText) {
      generatePreview();
      closePopoverMenu();
    }
  }, [transformedText]);

  useEffect(() => {
    if (!previewModalProps.show) setPreviewHtml(undefined);
  }, [previewModalProps.show]);

  const [selected, setSelected] = useState(false);
  // only fetch this template until the user interacts with the card - i.e. editing the template title, previewing the template, and routing to the email composer
  const { refetch, isLoading } = EmailTemplateQueries.useGetSystemEmailTemplate(
    { templateId: templateId ?? '' },
    {
      enabled: !!selected && !!templateId && templateId !== 'new',
      onError: () => {
        alert.error(t('There was an error fetching the template.'));
      },
      onSettled: () => {
        setSelected(false);
      },
    }
  );

  const initializeTemplateStoreData = async () => {
    const template = await handleRefetchTemplate();
    if (subject) store.setSubject(subject);
    if (template?.templateHtml) store.setTemplateHtml(template?.templateHtml);
    if (template?.templateJson) store.setTemplateJson(template?.templateJson);
  };

  const handleTemplateClick = async () => {
    await initializeTemplateStoreData();
    if (templateId) navigateTo.composerWithTemplateId(templateId, campaignId, undefined, true);
  };

  const handleRefetchTemplate = async () => {
    if (isLoading) return;
    setSelected(true);
    const { data: template } = await refetch();
    return template;
  };

  const actions: TemplateActionsMenuOptions[] = [
    {
      Icon: <Icon name='view' />,
      label: t('Preview'),
      onClick: async () => {
        const template = await handleRefetchTemplate();
        if (!template) return;
        setPreviewHtml(template?.templateHtml);
      },
      trackingId: `${BulkEmailPrefixes.Templates}-default-preview-btn`,
    },
    {
      Icon: <Icon name='collapse-right' />,
      label: t('Continue With This Template'),
      onClick: () => handleTemplateClick(),
      trackingId: `${BulkEmailPrefixes.Templates}-default-continue-btn`,
    },
  ];

  return (
    <>
      <div
        css={{
          border: `2px solid ${theme.colors.neutral20}`,
          borderRadius: theme.borderRadius.medium,
          position: 'relative',
          cursor: 'pointer',
          '&:hover': {
            border: `2px solid ${theme.colors.primary50}`,
          },
        }}
      >
        <div
          onClick={() => handleTemplateClick()}
          css={{
            background: `linear-gradient(180deg, ${theme.colors.primary5} 0%, ${theme.colors.white} 100%)`,
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            padding: theme.spacing(2),
            borderRadius: CUSTOM_BORDER_RADIUS,
          }}
          data-trackingid={`${BulkEmailPrefixes.Templates}-default-template-card-btn`}
        >
          <EmailThumbnail src={thumbnailUrl} alt={t('Default Template')} />
        </div>
        <div
          css={{
            position: 'absolute',
            bottom: 0,
            background: 'white',
            padding: theme.spacing(2),
            borderTop: `2px solid ${theme.colors.neutral20}`,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            rowGap: theme.spacing(1.5),
            borderBottomLeftRadius: theme.borderRadius.medium,
            borderBottomRightRadius: theme.borderRadius.medium,
            cursor: 'pointer',
          }}
        >
          <Text weight='bold'>{title}</Text>
          <div
            css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            onClick={() => handleTemplateClick()}
          >
            <Text color='light' size='small'>
              {dateText}
            </Text>
            <NakedButton {...getTriggerProps()}>
              <Icon name='more' />
            </NakedButton>
          </div>
        </div>
        <PopoverMenu {...getMenuProps()}>
          {actions.map(({ Icon, label, onClick, textColor = 'default', trackingId }, index) => (
            <ListRow
              key={index}
              css={{
                border: 'unset',
                display: 'flex',
                columnGap: theme.spacing(1),
                padding: theme.spacing(1, 2),
                cursor: 'pointer',
              }}
              onClick={onClick}
              trackingId={trackingId}
            >
              {Icon}
              <Text color={textColor}>{label}</Text>
            </ListRow>
          ))}
        </PopoverMenu>
      </div>
      {previewModalProps.show && (
        <EmailPreviewModal
          deviceSizeProps={{ ...deviceSizeProps, value: deviceSizeProps?.value as DeviceSize }}
          modalProps={previewModalProps}
          previewHtml={transformedText}
          setPreviewHtml={setPreviewHtml}
        />
      )}
    </>
  );
};
