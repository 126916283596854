import { WeavePopNotification } from '@frontend/types';
import { PausePreferenceNotificationComponent } from '../notification-preferences/pause-preference-notification';
import { PreferenceNotification } from '../notification-queue';
import { BaseNotificationComponentProps } from './base';
import { CallRecordTagNotificationComponent } from './call-record-tag-notification';
import { ChatNotificationComponent } from './chat-notification';
import { FaxNotificationComponent } from './fax-notification';
import { FormSubmissionNotificationComponent } from './form-notification';
import { IncomingCallNotificationComponent } from './incoming-call-notification';
import { MessageFollowupNotificationComponent } from './message-followup-notification';
import { MissedCallNotificationComponent } from './missed-call-notification';
import { PaymentNotificationComponent } from './payment-notification';
import { ReviewNotificationComponent } from './review-notification';
import { ScheduleRequestNotificationComponent } from './schedule-request-notification';
import { SmsMessageNotificationComponent } from './sms-message-notification';
import { SmsTagNotificationComponent } from './sms-tag-notification';

export const NotificationComponent = ({
  notification,
  ...props
}: BaseNotificationComponentProps<WeavePopNotification>) => {
  if (notification.type === 'preference') {
    switch (notification.preferenceType) {
      case 'pause-notifications':
        return (
          <PausePreferenceNotificationComponent notification={notification as PreferenceNotification} {...props} />
        );
      default: {
        return null;
      }
    }
  }

  switch (notification.type) {
    case 'chat-message-new':
      return <ChatNotificationComponent notification={notification} {...props} />;
    case 'fax-new':
      return <FaxNotificationComponent notification={notification} {...props} />;
    case 'form-submission-new':
      return <FormSubmissionNotificationComponent notification={notification} {...props} />;
    case 'missed-call':
      return <MissedCallNotificationComponent notification={notification} {...props} />;
    case 'incoming-call':
      return <IncomingCallNotificationComponent notification={notification} {...props} />;
    case 'call-record-tag':
      return <CallRecordTagNotificationComponent notification={notification} {...props} />;
    case 'sms-message-new':
      return <SmsMessageNotificationComponent notification={notification} {...props} />;
    case 'review-new':
      return <ReviewNotificationComponent notification={notification} {...props} />;
    case 'schedule-request-new':
      return <ScheduleRequestNotificationComponent notification={notification} {...props} />;
    case 'sms-tag':
      return <SmsTagNotificationComponent notification={notification} {...props} />;
    case 'message-followup':
      return <MessageFollowupNotificationComponent notification={notification} {...props} />;
    case 'payments':
      return <PaymentNotificationComponent notification={notification} {...props} />;
    default: {
      const _exhaustive: never = notification;
      console.warn('Tried to render a notification component with no matching signature', notification, _exhaustive);
      return null;
    }
  }
};
