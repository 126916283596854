import { useCallback, useMemo } from 'react';
import { NotificationType } from '@weave/schema-gen-ts/dist/shared/notification/notifications.pb';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { useQueryClient } from 'react-query';
import { PhoneCallsApi } from '@frontend/api-phone-calls';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { useCallRecordTagNotification } from '@frontend/notifications';
import { useAppScopeStore } from '@frontend/scope';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { GetWebsocketEventHandler, useWebsocketEventSubscription } from '@frontend/websocket';
import { useAlert } from '@frontend/design-system';

export const useWebsocketPhonesTagEventSubscription = () => {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const { setShow } = useSlidePanelShallowStore('setShow');
  const { selectedLocationIds } = useAppScopeStore();

  const hasAccesstoCallRecordingMap = useMemo(() => {
    return selectedLocationIds.reduce((accessMap, locationId) => {
      accessMap[locationId] = hasSchemaACL(locationId, Permission.CALL_RECORDING_READ);
      return accessMap;
    }, {} as Record<string, boolean>);
  }, [selectedLocationIds]);

  const hasAccesstoCallRecording = useMemo(() => {
    return Object.values(hasAccesstoCallRecordingMap).some((hasAccess) => hasAccess);
  }, [hasAccesstoCallRecordingMap]);

  const { create: createNotification } = useCallRecordTagNotification({
    onView: async (event) => {
      const { payload: params } = event;

      try {
        const callRecordData = await queryClient.fetchQuery({
          queryKey: [params.locationId, params.callRecordId],
          queryFn: () =>
            PhoneCallsApi.getCallRecord({
              callRecordId: params.callRecordId,
              locationId: params.locationId,
              startTime: params.startedAt,
              endTime: params.startedAt,
              includeCallRecording: true,
            }),
          staleTime: 0,
        });
        setShow(true, 'callDetails', {
          rows: callRecordData.record ? [{ ...callRecordData.record, tags: [] }] : [],
          currentRowId: callRecordData?.record?.id,
          instanceId: null,
          hasAccessToRecordings: hasAccesstoCallRecording,
          toggleRowSelected: () => {},
          callDetailsQueryKey: [],
          sectionFocus: 'details',
        });
      } catch (error) {
        alert.error('Error fetching call record data');
      }
    },
  });

  const eventHandler = useCallback<GetWebsocketEventHandler<NotificationType.CALL_RECORD_TAG>>(
    (event) => {
      const { params } = event;

      createNotification({
        id: event.id,
        timestamp: Date.now(),
        type: 'call-record-tag',
        payload: params,
        state: {
          paused: false,
          status: 'unread',
        },
      });
    },
    [createNotification]
  );
  return useWebsocketEventSubscription(NotificationType.CALL_RECORD_TAG, eventHandler);
};
