import { NotificationType } from '@weave/schema-gen-ts/dist/shared/notification/notifications.pb';
import { NotificationSettingsTypes } from '@frontend/api-notification-settings';
import { WeavePopNotification } from '@frontend/types';

// for context on this list, see this google sheet: https://docs.google.com/spreadsheets/d/1szE3fFaBKbE84t3Ltcn6oy8SpXDOVx-obe-3ZvozJQA/edit#gid=0
export const allowedNotificationTypes: {
  id: NotificationSettingsTypes.NotificationSetting['id'];
  type: WeavePopNotification['type'];
  displayName: string;
  firestoreType: NotificationType;
}[] = [
  {
    type: 'chat-message-new',
    /**
     * these id's are included here so that when a websocket message comes in,
     * we can check if the user has opted in to receive notifications for that type.
     * this is currently the only way to map a websocket message type to the user's notification settings.
     */
    id: '897b5b00-57cf-423e-b13e-646ab73880fb',
    displayName: 'Team Chat',
    firestoreType: NotificationType.CHAT_MESSAGE,
  },
  {
    type: 'fax-new',
    id: 'ec847541-be8c-4db2-97c0-b2fd4160334f',
    displayName: 'Fax',
    firestoreType: NotificationType.FAX,
  },
  {
    type: 'form-submission-new',
    id: 'f8f78f0f-f045-490e-b312-bf0facf45f1e',
    displayName: 'Forms',
    firestoreType: NotificationType.FORMS,
  },
  {
    type: 'review-new',
    id: '60ed9822-d248-4e0c-9ac5-99bff3346a64',
    displayName: 'Reviews',
    firestoreType: NotificationType.REVIEWS,
  },
  {
    type: 'sms-message-new',
    id: '46a812f4-aafc-4451-8267-faf61d14fd08',
    displayName: 'SMS Messages',
    firestoreType: NotificationType.SMS,
  },
  {
    type: 'payments',
    id: 'c60414e4-2b8a-46bd-ab73-c72b0834eb68',
    displayName: 'Payments',
    firestoreType: NotificationType.PAYMENTS,
  },

  {
    type: 'schedule-request-new',
    id: '3b0b62c3-9ac4-4022-8e7b-795fda0da62f',
    displayName: 'Schedule Request',
    firestoreType: NotificationType.SCHEDULE_REQUEST,
  },
];

export const phoneNotificationTypes: {
  id: NotificationSettingsTypes.NotificationSetting['id'];
  type: WeavePopNotification['type'];
  displayName: string;
  firestoreType: NotificationType;
}[] = [
  {
    type: 'incoming-call',
    /**
     * these id is taken from usernotificationsettings api
     * we can check if the user has opted in to receive notifications for that type.
     */

    id: 'e50f515c-34bd-4313-ac21-a87332fb30d9',
    displayName: 'Call Pop',
    firestoreType: NotificationType.PHONE_CALL,
  },
  {
    type: 'missed-call',
    id: '03cd1035-1477-4d5c-af10-a26f85249921',
    displayName: 'Missed Call',
    firestoreType: NotificationType.MISSED_CALL,
  },
];

type TagNotificationType = (typeof allowedNotificationTypes)[0] & {
  name: string;
  description: string;
};

export const tagNotificationsType: TagNotificationType[] = [
  {
    id: '6928418b-0c1b-4b9b-ab1f-eee3d4c381e8',
    type: 'sms-tag',
    displayName: 'SMS Tags',
    firestoreType: NotificationType.SMS_TAG_V2,
    name: 'SMS Tags',
    description: 'Receive notifications when an SMS is tagged',
  },
  {
    id: '5dc1b862-b9d4-49b9-be86-4139a5359a29',
    type: 'call-record-tag',
    displayName: 'Call Record Tags',
    firestoreType: NotificationType.CALL_RECORD_TAG,
    name: 'Call Record Tags',
    description: 'Receive notifications when a call record is tagged',
  },
  // This is going to be added soon
  // {
  //   id: 'b3290d60-4e1a-4391-a97d-2b8873225203',
  //   type: 'voicemail-tag',
  //   name: 'Voicemail Tags',
  //   description: 'Receive notifications when a voicemail is tagged',
  // },
];

export const allNotificationTypes = [...allowedNotificationTypes, ...phoneNotificationTypes, ...tagNotificationsType];
