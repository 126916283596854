import { useQuery } from 'react-query';
import { getInvoice, getInvoiceQueryKey, invoiceCacheOptions } from '@frontend/api-invoices';
import { useMerchant } from '@frontend/payments-hooks';

export const useInvoiceQuery = (invoiceId: string | null, locationIds: string[]) => {
  const locationId = locationIds[0];
  const { paymentsUrl } = useMerchant({ locationId });

  const queryKey = getInvoiceQueryKey(locationId, invoiceId!);

  const { isLoading, isError, data, isFetched, refetch } = useQuery({
    queryKey,
    queryFn: () => getInvoice(paymentsUrl!, invoiceId!, locationIds),
    enabled: !!paymentsUrl && !!invoiceId,
    ...invoiceCacheOptions,
  });
  return {
    invoice: data,
    isLoading,
    isError,
    isFetched,
    refetch,
    queryKey,
  };
};
