import { css } from '@emotion/react';
import { CallStatus, Direction as CallDirection } from '@weave/schema-gen-ts/dist/shared/phone/v1/callrecord/enums.pb';
import dayjs from 'dayjs';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { GetWeavePopNotificationByType, NotificationActions } from '@frontend/types';
import { theme } from '@frontend/theme';
import { Chip, PhoneIncomingIcon, PhoneMissedIcon, PhoneOutgoingIcon, Text } from '@frontend/design-system';
import { BaseNotificationComponentProps, BaseNotificationInner, BaseNotificationOuter } from './base';

type Props = BaseNotificationComponentProps<GetWeavePopNotificationByType<'call-record-tag'>>;

const callStatus = {
  [CallStatus.CALL_STATUS_ABANDONED]: 'Abandoned',
  [CallStatus.CALL_STATUS_ANSWERED]: 'Answered',
  [CallStatus.CALL_STATUS_FORWARDED]: 'Forwarded',
  [CallStatus.CALL_STATUS_MISSED]: 'Missed',
  [CallStatus.CALL_STATUS_UNKNOWN]: 'Unknown',
};

export const CallRecordTagNotificationComponent = ({ isHistorical, notification, emit, ...rest }: Props) => {
  const { t } = useTranslation();

  const { contactName, callerNumber, startedAt, direction, status, tagsPayload } = notification.payload;
  const actions: NotificationActions = [
    {
      label: t('View'),
      action: () => {
        emit({ action: 'view', payload: undefined }, notification);
      },
    },
  ];

  const getStatus = (status: CallStatus, direction: string) => {
    return direction === CallDirection.DIRECTION_INBOUND ? callStatus[status] : '';
  };

  return (
    <BaseNotificationOuter notification={notification} emit={emit} isHistorical={isHistorical} {...rest}>
      <BaseNotificationInner
        onClose={() => emit({ action: 'ignore', payload: undefined }, notification)}
        title={`Tags Applied to ${getStatus(status, direction)} Call`}
        actions={actions}
        body={
          <section
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: ${theme.spacing(1)};
            `}
          >
            <Chip.Tag color={tagsPayload[0]?.color}>{tagsPayload[0]?.name}</Chip.Tag>
            <Text
              weight='bold'
              size='medium'
              css={css`
                margin: 0;
              `}
            >
              {isNaN(Number(contactName)) ? contactName : formatPhoneNumber(callerNumber)}
            </Text>
            <div
              css={css`
                display: flex;
                gap: ${theme.spacing(0.5)};
                align-items: center;
              `}
            >
              {direction === CallDirection.DIRECTION_OUTBOUND && <PhoneOutgoingIcon size={16} />}
              {direction === CallDirection.DIRECTION_INBOUND && status !== CallStatus.CALL_STATUS_MISSED && (
                <PhoneIncomingIcon size={16} />
              )}
              {direction === CallDirection.DIRECTION_INBOUND && status === CallStatus.CALL_STATUS_MISSED && (
                <PhoneMissedIcon size={16} />
              )}

              <Text
                size='medium'
                css={css`
                  margin: 0;
                `}
              >
                {dayjs(startedAt).format('MMM DD YYYY, hh:mm A')}
              </Text>
            </div>
          </section>
        }
        id={notification.id}
        location={notification.location}
        timestamp={notification.timestamp}
        notificationType={notification.type}
        {...rest}
      ></BaseNotificationInner>
    </BaseNotificationOuter>
  );
};
