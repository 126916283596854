import { css } from '@emotion/react';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { InvoiceDetailModel } from '@frontend/api-invoices';
import { MerchantPlanTypes } from '@frontend/api-payments';
import { useTranslation } from '@frontend/i18n';
import { PaymentsFeatureFlags, useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { theme } from '@frontend/theme';
import { Button, Text, useTooltip } from '@frontend/design-system';
import { isPaidInvoice } from '../../utils';
import {
  CollectPaymentModalSteps,
  useCollectPaymentModal,
  useValidEmailFromInvoice,
  useValidPhoneNumberFromInvoice,
} from '../hooks';
import SunbitIcon from './assets/sunbit.svg?react';

export const styles = {
  paymentMethods: (newUI: boolean) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: ${newUI ? 'center' : 'flex-start'};
    gap: ${theme.spacing(1)};
    ${newUI &&
    `
      background-color: ${theme.colors.neutral5};
      padding: ${theme.spacing(2)};
    `}
    & > button {
      height: ${theme.spacing(8)};
      ${newUI &&
      `
        background-color: ${theme.colors.white};
        span {
          flex-direction: column;
        }
      `}
    }
  `,
  sunbitButton: css`
    display: flex;
    gap: ${theme.spacing(1)};
  `,
  iconButton: css`
    text-transform: none;
    width: 30%;
  `,
  sunbitButtonTitle: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  icon: css`
    margin-right: ${theme.spacing(1)};
    width: ${theme.spacing(2)};
    height: ${theme.spacing(2)};
  `,
  sunbitIcon: (disableSunbitIcon: boolean) =>
    css`
      ${disableSunbitIcon && `opacity: 0.25;`};
    `,
};

const MINIMUM_SUNBIT_AMOUNT = 6000;
type PaymentMethodProps = {
  modernUI: boolean;
  invoice?: InvoiceDetailModel;
};

export const PaymentMethods = ({ modernUI, invoice }: PaymentMethodProps) => {
  const { t } = useTranslation('payments');
  const { locationId: defaultLocationId } = useMultiQueryUtils();
  const { stripeLocationId, merchant } = useMerchant({
    locationId: invoice?.locationId ?? defaultLocationId ?? '',
  });

  const { goToStep } = useCollectPaymentModal();
  const { firstValidPhoneNumber } = useValidPhoneNumberFromInvoice(invoice);
  const { firstValidEmail } = useValidEmailFromInvoice(invoice);
  const { Tooltip: TtpTooltip, triggerProps: ttpTooltipTriggerProps, tooltipProps: ttpTooltipProps } = useTooltip();
  const {
    Tooltip: EmailTooltip,
    triggerProps: EmailTooltipTriggerProps,
    tooltipProps: EmailTooltipProps,
  } = useTooltip();
  const {
    Tooltip: SunbitTooltip,
    triggerProps: sunbitTooltipTriggerProps,
    tooltipProps: sunbitTooltipProps,
  } = useTooltip();
  const { aggregateValue: enableACHOnFile } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.ACHOnFile,
    locationIds: [invoice?.locationId ?? ''],
  });
  const { aggregateValue: enableShareInEmail } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.ShareInEmail,
    locationIds: [invoice?.locationId ?? ''],
  });

  /**
   * Super temporary dev flag.
   * Should be removed within 1 month.
   * ~ Daniel (2025-03-06)
   */
  const { aggregateValue: forceShowTerminalCollect } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.JustifiTerminalDevOverride,
    locationIds: [invoice?.locationId ?? ''],
  });

  const isAcceptableSunbitAmount = !!invoice?.billedAmount && invoice.billedAmount >= MINIMUM_SUNBIT_AMOUNT;
  const customerPhoneNumberError = t('No valid phone number');
  const customerEmailError = t('No valid email id');
  const isInvoicePaid = isPaidInvoice(invoice);

  const legitShowTerminalChecks =
    !!stripeLocationId &&
    !!merchant?.planName &&
    (merchant?.planName === MerchantPlanTypes.FullPayments || merchant?.planName === MerchantPlanTypes.Full);

  const shouldShowTerminal = forceShowTerminalCollect || legitShowTerminalChecks;

  return (
    <section css={styles.paymentMethods(modernUI)}>
      <Button
        variant='secondary'
        css={styles.iconButton}
        onClick={() => goToStep(CollectPaymentModalSteps.ShareInMessage)}
        disabled={!firstValidPhoneNumber || isInvoicePaid}
        {...(!firstValidPhoneNumber ? ttpTooltipTriggerProps : {})}
        iconName='messaging'
      >
        {t('Text to Pay')}
        <TtpTooltip {...ttpTooltipProps}>{customerPhoneNumberError}</TtpTooltip>
      </Button>
      {enableShareInEmail && (
        <Button
          variant='secondary'
          css={styles.iconButton}
          onClick={() => goToStep(CollectPaymentModalSteps.ShareInEmail)}
          disabled={!firstValidEmail || isInvoicePaid}
          {...(!firstValidEmail ? EmailTooltipTriggerProps : {})}
          iconName='email'
        >
          {t('Share in Email')}
          <EmailTooltip {...EmailTooltipProps}>{customerEmailError}</EmailTooltip>
        </Button>
      )}
      {shouldShowTerminal && (
        <Button
          variant='secondary'
          css={styles.iconButton}
          onClick={() => goToStep(CollectPaymentModalSteps.ReaderSelection)}
          iconName='credit-card'
          disabled={isInvoicePaid}
        >
          {t('Pay at Terminal')}
        </Button>
      )}
      <Button
        variant='secondary'
        css={styles.iconButton}
        onClick={() => goToStep(CollectPaymentModalSteps.CardOnFile)}
        iconName='user'
        disabled={isInvoicePaid}
      >
        {enableACHOnFile ? t('Card/ACH on File') : t('Card on File')}
      </Button>
      <Button
        variant='secondary'
        css={styles.iconButton}
        onClick={() => goToStep(CollectPaymentModalSteps.ManualCardEntry)}
        iconName='edit-small'
        disabled={isInvoicePaid}
      >
        {t('Manual Card Entry')}
      </Button>
      <Button
        variant='secondary'
        css={[styles.iconButton, styles.sunbitButton]}
        onClick={() => goToStep(CollectPaymentModalSteps.Sunbit)}
        disabled={!isAcceptableSunbitAmount || !firstValidPhoneNumber || isInvoicePaid}
        {...(!firstValidPhoneNumber ? sunbitTooltipTriggerProps : {})}
      >
        <div css={styles.sunbitButton}>
          <div css={styles.sunbitButtonTitle}>
            <SunbitIcon css={[styles.icon, styles.sunbitIcon(!isAcceptableSunbitAmount || isInvoicePaid)]} />
            <div>
              {t('Sunbit')}
              {!isAcceptableSunbitAmount && (
                <Text size='small' color='disabled'>
                  {t('($60.00 min.)')}
                </Text>
              )}
            </div>
          </div>
          {<SunbitTooltip {...sunbitTooltipProps}>{customerPhoneNumberError}</SunbitTooltip>}
        </div>
      </Button>
    </section>
  );
};
